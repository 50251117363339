//カラー

// Brand
$ecCube_navy: #2f3f4e;
$ecCube_yellow: #f7d622;

// Theme
$navy80: #2f3f4e; //アイコン等
$navy70: #54687A; //アイコン等
$navy60: #7c90a2; //アイコン等
$dllNavy20: #d6d9e0; //メインナビボーダー

// Gray Scale
$black: #000;
$black85: #262626; //通常のテキスト
$black65: #595959; //キャプション等
$black40: #999; //プレースホルダ
$black20: #ccc; //ボーダーカラー

// Background
$paleBlue: #eff0f4; //メインの背景色
$paleBlue60 : #f5f6f8; //メインナビ等背景色
$paleBlue100: #F8F9FA;
$paleRed: #faf1f1; //エラー
$white: #fff; //ブロックの背景色

// Overlay
$black85: rgba(15,15,15,.85);
$black65: rgba(15,15,15,.65);
$black05: rgba(15,15,15,.05); //hover時の色変化に使用
$white15: rgba(255,255,255,.15); //hover時の色変化に使用

// Accent
$blue: #437ec4; //進む・リンク
$green: #25b877; //成功
$lightGreen: #5AB67C;
$yellow: #eeb128; //警告
$red: #c04949; //失敗・危険

// Button
$lochmara: #527dbf;
$iron: #d1d1d1;
$mineShaft: #333;
$boulder: #797979;
$brickRedLight: #b2514d;
$blueBayou: #586878;
$seaShell: #f0f0f0;
$wildSand: #f3f4f6;
$gothic: #8090a0;
